import React, {
    useMemo,
    useLayoutEffect,
    useEffect,
    useCallback,
    useRef,
} from 'react';
import {Row, Col, Form} from 'react-bootstrap';
import {Icon, CBCButton, Loader} from 'shared/helpers';
import {useAppContext} from 'contexts';
import {DispatchMethod, useJobForm, useJobFormSubmitHandler} from 'hooks';
import {FileUpload} from 'components';
import {Formik, FormikErrors, FormikProps} from 'formik';
import {useParams} from 'react-router-dom';
import {Dropdown, FormControl, MobileWrapper} from 'shared';
import {cloneDeep, isEmpty} from 'lodash';
import {depotListState, selectedDepotState} from 'store/customer/depotSlice';
import {Depot} from 'components/manufacturer/DepotSettings/depotApi';
import {shallowEqual} from 'react-redux';
import {useAppSelector} from 'store/customer';
import {ErrorHandler} from 'shared/components/ErrorHandler';
import {CancelButton} from 'shared/components/CancelButton';
import {DeliveryAddressList} from 'components/customer/Job/DeliveryAddressList';
import DispatchMethodCard from './components/DispatchMethodCard';
import PickupLocation from './components/PickupLocation';
import DispatchNote from './components/DispatchNote';
import {useTabletSize} from 'shared/helpers/DeviceSize';

import './Job.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import {DateTime} from '@cabinetsbycomputer/datetime';

export const StyledPicker = styled.div`
    .react-datepicker__triangle {
        transform: translate(25px, 0px) !important;
    }
    .react-datepicker__input-container {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        input {
            padding: 6px 32px 6px 12px;
        }
        svg path {
            fill: rgb(var(--primary_colour));
        }
    }
    .react-datepicker__day--selected {
        background-color: rgb(var(--primary_colour));
    }
`;

export const Job = () => {
    const {jobId} = useParams();
    const {
        states,
        dispatchMethods,
        allowFileUpload,
        initialValues,
        JobSchema,
        loading,
        updateAttachmentsCount,
        selectDepot,
        allowDeliveryDateRequest,
        minimumLeadTime = 0,
    } = useJobForm(jobId);
    const {jobFormSubmitHandler, loadingSave} = useJobFormSubmitHandler(jobId);
    const {userProfile, setIsMobileMenuVisible} = useAppContext();
    const depotList = useAppSelector(depotListState, shallowEqual);
    const isTabletSize = useTabletSize();

    const depots = useMemo(() => {
        if (typeof depotList === 'undefined') {
            return [];
        }

        return (
            depotList &&
            depotList.map((depot: Depot) => ({
                id: depot.id,
                name: depot.depot_name + ` (${depot.depot_address})`,
            }))
        );
    }, [depotList]);

    const formSpan = useMemo(() => {
        const labelSpan = isTabletSize ? 4 : 3;

        return {
            label: labelSpan,
            value: 12 - labelSpan,
        };
    }, [isTabletSize]);

    const mainColSpan = useMemo(() => {
        return isTabletSize ? {span: 10, offset: 1} : {span: 8, offset: 2};
    }, [isTabletSize]);

    const depotSelected = useAppSelector(selectedDepotState, shallowEqual);

    useLayoutEffect(() => {
        setIsMobileMenuVisible(false);

        return () => {
            setIsMobileMenuVisible(true);
        };
    }, []);

    const handleDateChange = useCallback(
        (
                setFieldValue: (
                    field: string,
                    value: boolean | number | string,
                    shouldValidate?: boolean
                ) => Promise<void | FormikErrors<typeof initialValues>>
            ) =>
            (date: Date) =>
                void setFieldValue(
                    'requestedDeliveryDate',
                    DateTime.fromDate(date).format().toString()
                ),
        []
    );

    const preventDefault = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => event.preventDefault(),
        []
    );

    return (
        <Loader loader={loading || loadingSave}>
            <Row className="job-form">
                <Col md={mainColSpan}>
                    <h1>
                        <Icon iconName="Headers-New-Job.svg" />
                        <label>Job Details</label>
                    </h1>

                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={JobSchema}
                        onSubmit={jobFormSubmitHandler}>
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            setFieldValue,
                            handleSubmit,
                            setFieldTouched,
                            submitCount,
                        }: FormikProps<typeof initialValues>) => {
                            const prevSubmitCount = useRef(submitCount);
                            const errorRef = useRef<HTMLDivElement>(null);
                            const [formFieldErrors, fileUploadErrors] =
                                useMemo(() => {
                                    const formFieldErrors = cloneDeep(errors);
                                    const fileUploadErrors = {};

                                    if (
                                        formFieldErrors.hasOwnProperty('files')
                                    ) {
                                        fileUploadErrors['files'] =
                                            formFieldErrors['files'];

                                        delete formFieldErrors.files;
                                    }

                                    return [formFieldErrors, fileUploadErrors];
                                }, [errors]);

                            useEffect(() => {
                                if (
                                    parseInt(
                                        (values as {dispatch: string}).dispatch
                                    ) == DispatchMethod.PICKUP &&
                                    typeof jobId === 'undefined'
                                ) {
                                    void setFieldValue('street', '');
                                    void setFieldValue('postcode', '');
                                    void setFieldValue('state', '');
                                    void setFieldValue('suburb', '');
                                    void setFieldValue('city', '');
                                }
                            }, [
                                (values as {dispatch: string}).dispatch,
                                jobId,
                            ]);

                            useEffect(() => {
                                // this effect will only be triggered whenever form is submitted and validated with errors
                                if (
                                    submitCount > prevSubmitCount.current &&
                                    !isEmpty(errors) &&
                                    !isEmpty(touched)
                                ) {
                                    prevSubmitCount.current = submitCount;
                                    errorRef.current.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'end',
                                    });
                                }
                            }, [submitCount, errors, touched]);

                            return (
                                <>
                                    <Row
                                        className="jobFormErrors"
                                        ref={errorRef}>
                                        <Col>
                                            <ErrorHandler
                                                errors={formFieldErrors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </Row>

                                    <Form
                                        id="cbc-job-form"
                                        noValidate
                                        onSubmit={handleSubmit}
                                        style={
                                            isTabletSize
                                                ? {
                                                      paddingLeft: 0,
                                                      paddingRight: 0,
                                                  }
                                                : {}
                                        }
                                        className="cbc-form">
                                        <Form.Group
                                            as={Row}
                                            controlId="jobName_">
                                            <Form.Label
                                                column
                                                md={formSpan.label}>
                                                Job Name:<span>*</span>
                                            </Form.Label>
                                            <Col md={formSpan.value}>
                                                <Form.Control
                                                    type="text"
                                                    name="jobName"
                                                    value={values.jobName}
                                                    onChange={(e) => {
                                                        if (!touched.jobName) {
                                                            setFieldTouched(
                                                                'jobName',
                                                                true,
                                                                true
                                                            );
                                                        }
                                                        handleChange(e);
                                                    }}
                                                    isInvalid={
                                                        touched.jobName &&
                                                        errors.jobName
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </Col>
                                        </Form.Group>
                                        {userProfile.depotSelectable &&
                                        userProfile.isDepotFunctionalityEnabled &&
                                        depots.length ? (
                                            <Form.Group
                                                as={Row}
                                                controlId="depotId_">
                                                <Form.Label
                                                    column
                                                    md={formSpan.label}>
                                                    {
                                                        userProfile.depotIdentification
                                                    }
                                                    :
                                                </Form.Label>
                                                <Col md={formSpan.value}>
                                                    <Dropdown
                                                        options={depots}
                                                        name="depotId"
                                                        placeholder={
                                                            'Select ' +
                                                            userProfile.depotIdentification
                                                        }
                                                        selectHandler={(
                                                            name,
                                                            value,
                                                            options
                                                        ) => {
                                                            selectDepot(
                                                                setFieldValue,
                                                                value
                                                            );
                                                        }}
                                                        value={values.depotId}
                                                        fullWidth
                                                    />
                                                </Col>
                                            </Form.Group>
                                        ) : null}

                                        <>
                                            <Form.Group
                                                as={Row}
                                                controlId="jobRefCode_">
                                                <Form.Label
                                                    column
                                                    md={formSpan.label}>
                                                    Job Reference Code:
                                                </Form.Label>
                                                <Col md={formSpan.value}>
                                                    <FormControl
                                                        type="text"
                                                        name="jobRefCode"
                                                        placeholder="Optional"
                                                        value={
                                                            values.jobRefCode
                                                        }
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group
                                                as={Row}
                                                controlId="contactNumber_">
                                                <Form.Label
                                                    column
                                                    md={formSpan.label}>
                                                    Contact Number:
                                                </Form.Label>
                                                <Col md={formSpan.value}>
                                                    <FormControl
                                                        type="text"
                                                        name="contactNumber"
                                                        placeholder="Optional"
                                                        value={
                                                            values.contactNumber
                                                        }
                                                        isInvalid={
                                                            touched.contactNumber &&
                                                            errors.contactNumber
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </>

                                        <Form.Group
                                            as={Row}
                                            controlId="description_">
                                            <Form.Label
                                                column
                                                md={formSpan.label}>
                                                Description:
                                            </Form.Label>
                                            <Col md={formSpan.value}>
                                                <FormControl
                                                    as="textarea"
                                                    name="description"
                                                    placeholder="Optional"
                                                    value={values.description}
                                                />
                                            </Col>
                                        </Form.Group>
                                        {allowDeliveryDateRequest ? (
                                            <Form.Group
                                                as={Row}
                                                controlId="requestedDeliveryDate_">
                                                <Form.Label
                                                    column
                                                    md={formSpan.label}>
                                                    Requested Delivery Date:
                                                    {!!userProfile?.requireDeliveryDateRequest ? (
                                                        <span>*</span>
                                                    ) : null}
                                                </Form.Label>
                                                <Col md={formSpan.value}>
                                                    <StyledPicker>
                                                        <DatePicker
                                                            showIcon
                                                            className={`form-control${
                                                                touched.requestedDeliveryDate &&
                                                                errors.requestedDeliveryDate
                                                                    ? ' is-invalid'
                                                                    : ''
                                                            }`}
                                                            wrapperClassName="full-width"
                                                            dateFormat="dd MMM yyyy"
                                                            selected={
                                                                (values.requestedDeliveryDate &&
                                                                    DateTime.parseCustom(
                                                                        values.requestedDeliveryDate
                                                                    ).get()) ||
                                                                null
                                                            }
                                                            placeholderText="Select a requested delivery date"
                                                            minDate={DateTime.now()
                                                                .add({
                                                                    days: minimumLeadTime,
                                                                })
                                                                .get()}
                                                            onChange={handleDateChange(
                                                                setFieldValue
                                                            )}
                                                            onKeyDown={
                                                                preventDefault
                                                            }
                                                            toggleCalendarOnIconClick
                                                        />
                                                    </StyledPicker>
                                                </Col>
                                            </Form.Group>
                                        ) : null}

                                        <section style={{marginBottom: '15px'}}>
                                            <strong
                                                className="primary-colour"
                                                style={{
                                                    marginBottom: '15px',
                                                    display: 'block',
                                                }}>
                                                How would you like to receive
                                                your order?
                                            </strong>

                                            <Row style={{margin: '0 -8px'}}>
                                                {dispatchMethods
                                                    ? dispatchMethods.map(
                                                          (method, index) => (
                                                              <DispatchMethodCard
                                                                  key={index}
                                                                  {...{
                                                                      method,
                                                                      values,
                                                                      userProfile,
                                                                      handleChange,
                                                                  }}
                                                              />
                                                          )
                                                      )
                                                    : null}
                                            </Row>
                                        </section>
                                        {
                                            // show only when fright available
                                            parseInt(values.dispatch) !=
                                                DispatchMethod.PICKUP &&
                                            userProfile.manufacturerFreightAvailable ? (
                                                <DeliveryAddressList />
                                            ) : null
                                        }

                                        {parseInt(values.dispatch) ==
                                            DispatchMethod.PICKUP && (
                                            <PickupLocation
                                                {...{
                                                    depotSelected,
                                                    userProfile,
                                                    states,
                                                }}
                                            />
                                        )}

                                        {parseInt(values.dispatch) !=
                                            DispatchMethod.PICKUP && (
                                            <DispatchNote />
                                        )}

                                        {allowFileUpload ? (
                                            <FileUpload
                                                fileUploadErrors={
                                                    fileUploadErrors
                                                }
                                                updateAttachmentsCount={
                                                    updateAttachmentsCount
                                                }
                                            />
                                        ) : null}

                                        <MobileWrapper>
                                            <Row>
                                                <Col xs={6}>
                                                    <CancelButton to="/v2/job/:jobId/dashboard" />
                                                </Col>
                                                <Col xs={6}>
                                                    <CBCButton
                                                        type="submit"
                                                        iconName="Button-Tick.svg"
                                                        className="job-button button-blue">
                                                        {jobId
                                                            ? 'Save'
                                                            : 'Create'}{' '}
                                                        Job
                                                    </CBCButton>
                                                </Col>
                                            </Row>
                                        </MobileWrapper>
                                    </Form>
                                </>
                            );
                        }}
                    </Formik>
                </Col>
            </Row>
        </Loader>
    );
};
