import React from 'react';
import {Routes, Route} from 'react-router-dom';

import {
    Login,
    RequireAuth,
    Accounts,
    LoginLayout,
    PasswordReset,
    NoAuth,
    SetPassword,
} from 'components';
import {useAppSelector} from 'store/customer';
import {selectRedirecting} from 'components/customer/Auth/store/authSlice';
import {Loader} from 'shared/helpers';

export const AuthGuard = ({children}: {children: JSX.Element}): JSX.Element => {
    const isRedirecting = useAppSelector(selectRedirecting);
    if (isRedirecting) {
        // display a loader rather continuing to load the actual page when location.replace is triggered
        return <Loader loader={true} />;
    }

    return (
        <Routes>
            <Route
                path="login"
                element={
                    <NoAuth>
                        <LoginLayout element={<Login />} />
                    </NoAuth>
                }
            />
            <Route
                path="reset-password"
                element={
                    <NoAuth>
                        <LoginLayout element={<PasswordReset />} />
                    </NoAuth>
                }
            />
            <Route
                path="set-password"
                element={
                    <NoAuth>
                        <LoginLayout element={<SetPassword />} />
                    </NoAuth>
                }
            />
            <Route
                path="accounts"
                element={
                    <NoAuth>
                        <Accounts />
                    </NoAuth>
                }
            />
            <Route path="*" element={<RequireAuth>{children}</RequireAuth>} />
        </Routes>
    );
};
