import React, {ReactElement, Suspense} from 'react';
import {Routes as OGRoutes, Route} from 'react-router-dom';
import Layout from 'shared/components/Layout';
import {Dashboard, Hardware, Job, Jobs, JobDashboard} from 'components';
import {Favourites} from 'components/customer/Favourites/Favourites';
import {Cart, ErrorBoundary, FourZeroFour, JobRoute} from 'shared';
import {Loader} from 'shared/helpers';

const Settings = React.lazy(() => import('./SettingsDefault'));
const Room = React.lazy(() => import('./RoomDefault'));
const BenchtopDesigner = React.lazy(() => import('./BenchtopDefault'));
const BenchtopModule = React.lazy(() => import('./BenchtopModuleDefault'));
const LayoutDesigner = React.lazy(() => import('./LayoutDefault'));
const ProductWrapper = React.lazy(() => import('./ProductDefault'));
const KDMaxWrapper = React.lazy(() => import('./KDMaxDefault'));
const QFPSwitch = React.lazy(() => import('./QFPDefault'));

export const Routes = (): ReactElement => {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader loader={true} />}>
                <OGRoutes>
                    <Route
                        path="/job/:jobId"
                        element={<JobRoute checkProcessingStatus={true} />}>
                        <Route
                            path=""
                            element={
                                <Layout withSidebar>
                                    <Job />
                                </Layout>
                            }
                        />
                    </Route>
                    <Route
                        path="/job/:jobId/hardware"
                        element={<JobRoute checkProcessingStatus={true} />}>
                        <Route
                            path=""
                            element={
                                <Layout withSidebar>
                                    <Hardware />
                                </Layout>
                            }
                        />
                    </Route>
                    <Route
                        path="/job/:jobId/room/:roomId"
                        element={<JobRoute checkProcessingStatus={true} />}>
                        <Route
                            path=""
                            element={
                                <Layout withSidebar>
                                    <Room />
                                </Layout>
                            }
                        />
                    </Route>
                    <Route
                        path="/job/:jobId/room/:roomId/benchtop-designer"
                        element={<JobRoute checkProcessingStatus={true} />}>
                        <Route
                            path=""
                            element={
                                <Layout withSidebar>
                                    <BenchtopDesigner />
                                </Layout>
                            }
                        />
                    </Route>
                    <Route
                        path="/job/:jobId/benchtop-module/*"
                        element={<JobRoute checkProcessingStatus={true} />}>
                        <Route
                            path="*"
                            element={
                                <Layout withSidebar>
                                    <BenchtopModule />
                                </Layout>
                            }
                        />
                    </Route>
                    <Route
                        path="/job/:jobId/room/:roomId/layout"
                        element={<JobRoute checkProcessingStatus={true} />}>
                        <Route
                            path=""
                            element={
                                <Layout withSidebar>
                                    <LayoutDesigner />
                                </Layout>
                            }
                        />
                    </Route>
                    <Route
                        path="/job/:jobId/room/:roomId/product"
                        element={<JobRoute checkProcessingStatus={true} />}>
                        <Route
                            path=""
                            element={
                                <Layout withSidebar>
                                    <ProductWrapper />
                                </Layout>
                            }
                        />
                    </Route>
                    <Route
                        path="/job/:jobId/room/:roomId/quick-flat-product"
                        element={<JobRoute checkProcessingStatus={true} />}>
                        <Route
                            path=""
                            element={
                                <Layout withSidebar>
                                    <QFPSwitch />
                                </Layout>
                            }
                        />
                    </Route>
                    <Route
                        path="/job/:jobId/room/:roomId/kdmax-import"
                        element={<JobRoute checkProcessingStatus={true} />}>
                        <Route
                            path=""
                            element={
                                <Layout withSidebar>
                                    <KDMaxWrapper />
                                </Layout>
                            }
                        />
                    </Route>
                    <Route
                        path="/job/:jobId/dashboard"
                        element={<JobRoute checkProcessingStatus={false} />}>
                        <Route
                            path=""
                            element={
                                <Layout withSidebar>
                                    <JobDashboard />
                                </Layout>
                            }
                        />
                    </Route>
                    <Route
                        path="/job/:jobId/room/:roomId/favourites"
                        element={<JobRoute checkProcessingStatus={true} />}>
                        <Route
                            path=""
                            element={
                                <Layout withSidebar>
                                    <Favourites />
                                </Layout>
                            }
                        />
                    </Route>
                    <Route path="/job/:jobId/cart" element={<JobRoute />}>
                        <Route
                            path=""
                            element={
                                <Layout withSidebar>
                                    <Cart />
                                </Layout>
                            }
                        />
                    </Route>
                    <Route path="/job" element={<JobRoute />}>
                        <Route
                            path=""
                            element={
                                <Layout>
                                    <Job />
                                </Layout>
                            }
                        />
                    </Route>
                    <Route
                        path="/job/:jobId/room"
                        element={<JobRoute checkProcessingStatus={true} />}>
                        <Route
                            path=""
                            element={
                                <Layout withSidebar>
                                    <Room />
                                </Layout>
                            }
                        />
                    </Route>
                    <Route
                        path="/account-settings/*"
                        element={
                            <Layout>
                                <Settings />
                            </Layout>
                        }
                    />

                    {/* NOTE: New way of using conditional route params. Watch out
                for changes in future to update this to more elegant code. */}
                    <Route
                        path="/jobs/:status"
                        element={
                            <Layout>
                                <Jobs />
                            </Layout>
                        }
                    />
                    <Route
                        path="/jobs"
                        element={
                            <Layout>
                                <Jobs />
                            </Layout>
                        }
                    />
                    {/* NOTE: New way of using conditional route params. Watch out
                for changes in future to update this to more elegant code. */}

                    <Route
                        path="/"
                        element={
                            <Layout>
                                <Dashboard />
                            </Layout>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <Layout>
                                <FourZeroFour />
                            </Layout>
                        }
                    />
                </OGRoutes>
            </Suspense>
        </ErrorBoundary>
    );
};
