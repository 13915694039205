import {useAppSelector} from 'store/customer';
import {selectQFPs} from 'components/customer/QFPRedux/store/qfpSlice';
import {shallowEqual} from 'react-redux';
import {useMemo} from 'react';

export const useUnsavedQFPs = () => {
    const qfps = useAppSelector(selectQFPs, shallowEqual);

    const {hasUnsavedQFPs, unsavedIndexes} = useMemo(() => {
        // these are products that are not persisted and not deleted
        // includes products that have been previously saved
        const unsavedQFPs = qfps
            .map((qfp, index) => ({
                ...qfp,
                index,
            }))
            .filter((qfp) => {
                return !qfp.persisted && !qfp.deleted;
            });

        // these are products that are not persisted, not deleted and
        // does not include products that have been previously saved
        const unsavedProducts = qfps
            .map((qfp, index) => ({
                ...qfp,
                index,
            }))
            .filter((qfp) => {
                return (
                    !qfp.persisted &&
                    !qfp.deleted &&
                    typeof qfp.product.job_cabinet_id === 'undefined'
                );
            });

        return {
            hasUnsavedQFPs: unsavedQFPs.length > 0,
            unsavedIndexes: unsavedProducts.map((qfp) => qfp.index),
        };
    }, [qfps]);

    return {hasUnsavedQFPs, unsavedIndexes};
};
