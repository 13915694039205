import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {genericMessageHandler, CBCButton} from 'shared/helpers';
import {useAddProductManually} from 'shared/helpers/addProductManually';
import {useRecentlyAddedActions, RECENTLY_ADDED_KEYS} from 'hooks';
import {useNotificationContext, useJobContext} from 'contexts';
import {ProductSearch} from 'components';
import {GridOptions} from 'components/customer/LayoutDesigner/LayoutMenuComponents/GridOptions';
import {ShapeOptions} from 'components/customer/LayoutDesigner/LayoutMenuComponents/ShapeOptions';
import {ProductOptions} from 'components/customer/LayoutDesigner/LayoutMenuComponents/ProductOptions';
import {LayoutFilter} from 'components/customer/LayoutDesigner/LayoutMenuComponents/LayoutFilter';
import {useAppDispatch} from 'store/customer';
import {invalidateProductAndCost} from 'components/customer/Product/store/productApi';
import {PartialJob} from 'shared/types/PartialJob';
import {Product} from 'components/customer/Product/entity/Product';
import {TDLD} from '@cabinetsbycomputer/top-down-layout-designer';

export const LayoutMenu = ({
    jobId,
    roomId,
    tdld,
    tdldState,
    showLayoutSettings,
    isRoomShapeConfirmed,
    setSearchCallback,
}: {
    jobId: string;
    roomId: string;
    tdld: TDLD;
    tdldState: {tdldData: any};
    showLayoutSettings: boolean;
    isRoomShapeConfirmed: boolean;
    setSearchCallback: () => void;
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {addRecentItem} = useRecentlyAddedActions();
    const {notify} = useNotificationContext();
    const {job, room} = useJobContext() as PartialJob;

    const addProductManually = useAddProductManually();

    const addProductCallback = useCallback(
        async (product: Product, width: number | string) => {
            try {
                const {productData} = await addProductManually({
                    product,
                    room,
                    width: Number(width),
                });
                dispatch(invalidateProductAndCost());

                addRecentItem(productData.type, RECENTLY_ADDED_KEYS.PRODUCT);

                genericMessageHandler(
                    notify,
                    {message: 'Product saved'},
                    'success'
                );

                tdld && tdld.reload();
            } catch (e) {
                navigate(
                    `/v2/job/${job.displayId}/room/${room.id}/product?product=${product.id}&width=${width}&goto=tdld&validate=1`
                );
            }
        },
        [room, notify, tdld, job]
    );

    const cancelButtonClick = useCallback(() => {
        navigate(`/v2/job/${jobId}/dashboard`);
    }, [jobId]);

    return (
        <>
            <section
                className="sidebar-content layout-menu"
                id="layoutDesignerSidepanel"
                style={{position: 'relative'}}>
                <h2>Product Search</h2>
                <ProductSearch
                    disabled={!isRoomShapeConfirmed}
                    disabledText={'Please select and confirm room shape'}
                    callBack={addProductCallback}
                    setSearchCallback={setSearchCallback}
                />

                <ProductOptions tdld={tdld} tdldState={tdldState} />

                {showLayoutSettings ? (
                    <>
                        <LayoutFilter
                            tdld={tdld}
                            tdldData={tdldState.tdldData}
                        />

                        <GridOptions
                            tdld={tdld}
                            tdldData={tdldState.tdldData}
                        />
                    </>
                ) : null}
                <ShapeOptions
                    tdld={tdld}
                    tdldState={tdldState}
                    roomId={roomId}
                />
            </section>

            <section className="sidebar-footer">
                <CBCButton
                    iconName="Button-Tick.svg"
                    onClick={cancelButtonClick}
                    className="button-blue btn-sm btn-layout">
                    Close Layout
                </CBCButton>
            </section>
        </>
    );
};
